.posts {
  @apply m-auto border-l border-r border-gray-800;
  max-width: 600px;
}

.post {
  @apply border-b border-gray-800 px-4 py-2 text-sm;

  .title {
    @apply font-bold flex justify-between mb-2;

    .postControls {
      @apply text-gray-500 font-normal flex;
    }
  }
  .author {
    @apply text-gray-500 font-normal ml-1;
  }
  .content {
    @apply text-2xl;
    a {
      color: rgb(29, 155, 240);
    }
  }
}
