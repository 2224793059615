.commentFormContainer {
  @apply border-b border-gray-800 px-4 py-2;

  input,
  textarea {
    @apply bg-transparent block w-full;

    &:focus {
      @apply outline-none;
    }
  }
}

.commentFields {
  @apply border-b border-gray-800 mb-2;

  .contentField {
    @apply text-xl;
  }
}

.commentButton {
  @apply text-white font-bold px-4 rounded-full;
  background-color: rgb(29, 155, 240);
  min-width: 36px;
  min-height: 36px;

  &:disabled {
    @apply opacity-50;
  }
}

.commentSubmit {
  @apply flex flex-row-reverse;
}
