.comment {
  @apply border-b border-gray-800 px-4 py-2 text-sm;

  .title {
    @apply text-gray-500 font-normal;
  }
  .content {
    a {
      color: rgb(29, 155, 240);
    }
  }
}
