.post {
  @apply border-b border-gray-800 px-4 py-2 text-sm transition-colors duration-300 cursor-pointer;

  &:hover {
    @apply bg-gray-900;
  }
  .title {
    @apply font-bold flex justify-between;

    .postControls {
      @apply text-gray-500 font-normal flex;
    }
  }
  .author {
    @apply text-gray-500 font-normal ml-1;
  }
  .content {
    a {
      color: rgb(29, 155, 240);
    }
  }
}
